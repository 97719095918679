// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

$green-lighter: #25D956;
$green-light: #739d55;
$green-dark: #0C471B;
$green-darker: #022B19;

$gray-100: #fafafa;
$gray-200: #ededed;
$gray-300: #cbcbcb;
$gray-400: #b6b6b6;
$gray-500: #ababab;
$gray-600: #969696;
$black: #000;

$primary: $green-light;
$primary-light: lighten($primary, 20%);
$primary-disabled: lighten($primary, 30%);
$primary-dark: darken($primary, 20%);
$info:  #cbe6fc;

$highlight: $green-lighter;
$success: $green-lighter;
$body-color: $green-darker;
$link-color: $green-light;

$link-hover-color: $primary-dark;
$navbar-dark-active-color: $green-light;
$navbar-light-active-color: $green-light;

$display4-size: 2.5rem;

$line-height-base: 1.5;

$timeline-counter-size: 3rem;
$timeline-counter-font-size: 1.5rem;

$card-spacer-x-sm: 1.2rem;

$grid-breakpoint-xs: 0;
$grid-breakpoint-sm: 576px;
$grid-breakpoint-md: 768px;
$grid-breakpoint-lg: 992px;
$grid-breakpoint-xl: 1200px;
$grid-breakpoint-xxl: 1400px;

$grid-breakpoints: (
  xs: $grid-breakpoint-xs,
  sm: $grid-breakpoint-sm,
  md: $grid-breakpoint-md,
  lg: $grid-breakpoint-lg,
  xl: $grid-breakpoint-xl,
  xxl: $grid-breakpoint-xxl
);

$container-max-width-sm: 540px;
$container-max-width-md: 720px;
$container-max-width-lg: 960px;
$container-max-width-xl: 1140px;
$container-max-width-xxl: 1320px;

$container-max-widths: (
  sm: $container-max-width-sm,
  md: $container-max-width-md,
  lg: $container-max-width-lg,
  xl: $container-max-width-xl,
  xxl: $container-max-width-xxl,
);

.inset-0 {
  inset: 0;
}


$enable-validation-icons: false;